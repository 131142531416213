<template>
  <div>
    <vs-card>
      <div
        class="flex items-center justify-between w-full"
        slot="header">
        <div class="flex items-center justify-between">
          <p class="font-semibold lg:text-lg md:text-lg text-base">
            Doctor Oversight States
          </p>
        </div>
        <vs-button
          @click="handleSidebar(true)()"
          icon="add"
          type="border"
          class="lg:flex md:flex hidden"
        >
          Add oversight states
        </vs-button>
        <vs-button
          @click="handleSidebar(true)()"
          type="border"
          class="lg:hidden md:hidden block"
        >
          Add
        </vs-button>
      </div>
      <div class="w-full">
        <div class="product-box w-full m-auto overflow-auto">
          <div v-if="stateTags.length">
            <div
              v-for="(item, index) in stateTags"
              :key="index"
              class="overflow-hidden inline-block select-none"
            >
              <p
                class="product-box py-2 px-4 rounded-lg ml-2 flex items-center"
              >
                {{ item.name }}
                <vs-icon
                  @click="handleRemove(item)"
                  icon="close"
                  size="12px"
                  class="ml-2 cursor-pointer"
                >
                </vs-icon>
              </p>
            </div>
          </div>
          <div
            v-else
            class="text-center lg:text-lg md:text-lg text-base select-none"
          >
            Add an oversight states to get started
          </div>
        </div>
        <div v-if="stateTags.length" class="mt-2">
          <vs-button
            type="flat"
            color="danger"
            icon="close"
            @click="handleClearSelected"
            >Clear Selected States</vs-button
          >
        </div>
      </div>
    </vs-card>

    <vs-sidebar
      class="sidebarx oversight-states relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="isOpen"
    >
      <!-- HEADER -->
      <div class="header space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Oversight States</p>
        <p class="px-4">
          States that are checked mean this doctor has the authority
          to provide oversight for that state in our video consultations.
          Unchecking this means they will not have access to provide oversight,
          and will not be visible on the doctor calls list.
        </p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchString"
          @input="searchStates"
          placeholder="Search states"
          class="w-full pb-4 px-4"
        />
      </div>
      <!-- BODY -->
      <div class="scrollable-content">
        <div v-for="item in states" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selectedState"
            :disabled="item.hasDoctorOversightProduct"
            :vs-value="item._id"
          >
            <span
              :class="{ 'opacity-50 select-none': item.hasDoctorOversightProduct }"
            >
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer flex flex-col gap-3" slot="footer">
        <vs-button @click="handleSave" :disabled="isSaving">
          <vs-icon
            v-if="isSaving"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleSidebar(false)()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import states from 'states-us';
import _ from 'lodash';

const formattedStates = states.map(({ name, abbreviation: _id }) => ({ _id, name }))

export default {
  props: {
    doctorStates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      states: formattedStates,
      searchString: "",
      stateTags: [],
      selectedState: [],
      isSaving: false,
    }
  },
  methods: {
    handleSidebar(isOpen) {
      return () => {
        this.isOpen = isOpen
      }
    },
    handleSave(isClick = true) {
      this.isSaving = true;

      this.stateTags = [];

      if (this.selectedState.length) {
        this.stateTags = states.filter(({ abbreviation }) =>
          this.selectedState.includes(abbreviation)
        );
      }

      if (this.searchString) {
        this.searchString = "";
        this.states = formattedStates
      }

      this.isOpen = false;
      this.isSaving = false;

      if (isClick) {
        const input = this.stateTags.map(({ abbreviation, name }) => ({ abbreviation, name }))
        this.$emit('handleSave', input)
      }
    },
    searchStates: _.debounce(function (searchString) {
      const loweredCaseSearch = searchString.toLowerCase();
      const results = formattedStates.filter(({ _id, name }) =>
        name.toLowerCase().includes(loweredCaseSearch) || _id.toLowerCase().includes(loweredCaseSearch)
      )
      this.states = results
    }, 500),
    handleClearSelected() {
      this.stateTags = [];
      this.selectedState = [];

      this.$emit('handleSave', [])
    },
    handleRemove(item) {
      this.selectedState = this.selectedState.filter(
        (product) =>
          product !== states.find((p) => p.abbreviation === item.abbreviation).abbreviation
      );
      const stateTags = this.stateTags.filter(
        (product) => product.abbreviation !== item.abbreviation
      );

      const input = stateTags.map(({ abbreviation, name }) => ({ abbreviation, name }))
      this.$emit('handleSave', input)

      this.stateTags = stateTags
    },
  },
  watch: {
    doctorStates: function(doctorStates) {
      if (doctorStates.length) {
        this.selectedState = doctorStates.map(({ abbreviation: _id }) => _id)

        this.handleSave(false)
      }
    }
  }
}
</script>

<style lang="scss">
.oversight-states .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}

.oversight-states .custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.oversight-states .vs-sidebar--items {
  overflow: hidden !important;
}

.oversight-states .vs-sidebar--items .scrollable-content {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: calc(100% - 241px);
}

.oversight-states .vs-sidebar--items .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.oversight-states .vs-sidebar--footer .footer {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.sidebarx {
  .vs-sidebar {
    max-width: 350px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}
</style>